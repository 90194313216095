/* We selectedivly override base styles to create a #275766 theme */
.sui-search-box__submit {
    background: none;
    background-color: #275766;
  }
  

  .sui-layout-sidebar-toggle {
    color: #275766;
    border: 1px solid #275766;
  }
  
.sui-result__title,
.sui-result__title-link {
    color: #275766;
  }
  
.sui-facet-view-more {
    color: #275766;
  }
  